import TextField from '@mui/material/TextField'
import { AsYouType, isValidNumber } from 'libphonenumber-js'
import { ComponentProps, useEffect, useState } from 'react'
import { CODES_MAP } from './countries'

type IProps = Omit<
  ComponentProps<typeof TextField>,
  'onChange' | 'value' | 'placeholder'
> & {
  country: string
  initialPhoneNumber: string
  setPhoneState: (change: {
    phoneNumber: string
    isValidPhone: boolean
  }) => void
  hasError?: boolean
  setHasError?: (hasError: boolean) => void
}

export default function LoginPhone({
  country,
  initialPhoneNumber,
  setPhoneState,
  hasError,
  setHasError,
  ...rest
}: IProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber)
  const isValidPhone = isValidNumber(phoneNumber)

  useEffect(() => {
    if (hasError && setHasError) {
      setHasError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber])

  useEffect(() => {
    setPhoneState({ phoneNumber, isValidPhone })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPhone])

  useEffect(() => {}, [])

  return (
    <TextField
      fullWidth
      id="phoneNumber"
      name="phoneNumber"
      label="Número telefónico"
      type="phone"
      autoComplete="phone"
      placeholder={`${CODES_MAP[country]} XX XXXX XXXX`}
      value={phoneNumber}
      error={hasError}
      onChange={({ target: { value } }) => {
        const tel =
          value.length > 0 && value[0] !== '+'
            ? CODES_MAP[country] + value
            : value
        value = new AsYouType().input(tel)
        setPhoneNumber(value)
      }}
      {...rest}
    />
  )
}
